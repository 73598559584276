import React from 'react'

// COMPONENTS
import SEO from './../components/templates/seo';
import AboutCompanySection from './../components/organisms/aboutCompanySection';

export default function About(props) {
    return (
        <>
            <SEO title='O firmie' />
            <AboutCompanySection />
        </>
    )
}
